$(document).ready(function () {

	$('.phone').mask('0000-0000');


	$('#slider-one').slick({
		dots: true
	});

	$('#slider-two').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					slidesToShow: 3
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				}
			}
		  ]


	});
	
	$('#project-page-slider').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll:1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					slidesToShow: 3
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: true,
					centerMode: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false
				}
			}
		  ]


	});

	$('#slider-three').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					slidesToShow: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				}
			}
		  ]

	});
	
	
	$('#slider-product-detail').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: false,
		dots: true,
		arrows: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: false,
					slidesToShow: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				}
			}
		  ]

	});


	$('.filter-slider-one').slick({
		infinite: true,
		slidesToShow: 5,
		slidesToScroll:5,
		centerMode: true,
		dots:true,
		  responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: true
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			} 
		  ]

	});


	$('#slider-event').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]

	});


	$('#slider-news').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		arrows: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					slidesToShow: 3
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				}
			}
		  ]

	});



	$('#slider-team').slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 3,
		arrows: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					slidesToShow: 3
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				}
			}
		  ]

	});



	$('#slider-blog').slick({
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]

	});


	$('#slider-comment').slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		centerMode: true,
		slidesToShow: 3,
		dots: true,
	    responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				infinite: true,
		        centerMode: false,
				dots: true
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			} 
		  ]

	});

	$('#slider-reference').slick({
		infinite: true,
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		 responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				infinite: true,
		        centerMode: false,
				dots: true
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			} 
		  ]

	});

	
	
	$('a.anchor-link').click(function(){
		var body = $("html, body");
		body.stop().animate({scrollTop:0}, 500, 'swing', function() {  
		});
	});

 
	
	$('#lightgallery').lightGallery(); 
	
	$('#gallery-detail-gallery').lightGallery({
    	selector: 'a'
	});

	$('#project-page-slider').lightGallery({
		selector: 'a'
	});



	if ($("#header-one .lang-select select").length > 0) {
		$('#header-one .lang-select select').selectpicker({
			style: '',

		});

		$(document).on('change', '#header-one .lang-select select', function (e) {
			e.preventDefault();
			window.location = $(this).find('option:selected').val();
		});

	}

	$('.dropdown').on('show.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(200);
	});

	$('.dropdown').on('hide.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
	});

	$(".back-image").each(function () {
		var getBackground = $(this).attr('data-image');
		$(this).css('background-image', 'url(' + getBackground + ')');
	});


	var fileName = '';
	var $dropzone = $('.upload-1');


	$(".upload-1 input:file").change(function (){
		fileName = $(this)[0].files[0].name;
		$('.upload-1  .filename').html(fileName);
		$('.upload-1 .dropzone .upload').hide();
	});


	var $dropzone = $('.upload-2');


	$(".upload-2 input:file").change(function (){
		fileName = $(this)[0].files[0].name;
		$('.upload-2  .filename').html(fileName);
		$('.upload-2.dropzone .upload').hide();
	});






	// $(window).scroll(function fix_element() {
	//
	//
	// 	$('#project-detail-out-sidebar').css(
	// 		$(window).scrollTop() > 400
	// 			? { 'position': 'fixed', 'top': '20px' }
	// 			: { 'position': 'absolute', 'top': '0' }
	// 	);
	// 	return fix_element;
	// }());




});
$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {

	$('.filter-slider-one').slick('setPosition');


});
